import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
	apiKey: "AIzaSyBLft5eOJSEQ9n9g1UwDeIOEqaN896Fc5k",
	authDomain: "zfg-vue-app.firebaseapp.com",
	databaseURL: "https://zfg-vue-app.firebaseio.com",
	projectId: "zfg-vue-app",
	storageBucket: "zfg-vue-app.appspot.com",
	messagingSenderId: "492254115145"
};

const firebaseApp = initializeApp(firebaseConfig);

createApp(App).mount('#app')
