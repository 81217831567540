<template>
    <a :href="link" target="_blank">
        <div class="zfg-post">
            <img :src="thumbnail" height="128" width="192" alt="">
            <div class="zfg-post__info">
                <div class="zfg-post__title">{{ title }}</div>
                <div class="zfg-post__synopsis">{{ synopsis }}</div>
                <div class="zfg-post__author">by {{ state.author }} at {{ state.date }}</div>
            </div>
            <div v-if="Math.round((Date.now() - date) / 1000 / 60 / 60 / 24) < 4"
                 class="zfg-post__new"
                 title="Posted in the last 3 days.">
                <span class="material-symbols-outlined">local_fire_department</span>
            </div>
            <div class="zfg-post__category"
                 :class='`zfg-post__${category.toLowerCase()}`'>{{ category }}</div>
        </div>
    </a>
</template>

<script>
import { reactive, ref } from "vue";

export default {
    name: "NewsPost",
    props: {
        title: {
            type: String
        },
        synopsis: {
            type: String
        },
        thumbnail: {
            type: String
        },
        author: {
            type: String
        },
        category: {
            type: String
        },
        link: {
            type: String
        },
        date: {
            type: Number
        }
    },
    setup(props){
        const state = reactive({
            date: `${new Date(props.date).toLocaleDateString('lv-LV')}
                   ${new Date(props.date).toLocaleTimeString('lv-LV')}`,
            author: props.author.split('@')[0].split('.')[0],
            thumbnail: ref(props.thumbnail),
            link: ref(props.link)
        })

        return {
            state
        }
    }
}
</script>

<style scoped>
.zfg-post {
    position: relative;
    width: 512px;
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    /*box-shadow: 0 0 12px 2px #88b5b9;*/
    transition: all 200ms ease-in-out;
    border-bottom: 2px solid #628285;
    border-right: 2px solid #628285;
    color: #012344;
}
.zfg-post:hover {
    z-index: 999;
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 0 0 12px 4px #628285;
}
.zfg-post img {
    background: #2c3e50;
    object-fit: cover;
    min-height: 128px;
    min-width: 192px;
    border-radius: 8px;
}
.zfg-post__info {
    width: 100%;
    height: 128px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.zfg-post__title {
    font-family: Poppins;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.3em;
    margin: 4px 16px;
    text-align: left;
    color: #012344;
}
.zfg-post__synopsis {
    flex: 1;
    font-family: Poppins;
    margin: 0 16px;
    font-size: 12px;
    text-align: left;
    text-overflow: ellipsis;
}
.zfg-post__author {
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    text-align: right;
    margin: 0 16px;
}
.zfg-post__category {
    font-family: Poppins;
    font-size: 12px;
    position: absolute;
    right: 8px;
    top: -12px;
    background: #0c6265;
    color: #fff;
    border-radius: 8px;
    padding: 4px 16px;
    box-shadow: 0 0 4px 2px #88b5b9;
}
.zfg-post__new {
    position: absolute;
    left: -12px;
    top: -12px;
    background: #bb5d27;
    display: flex;
    border-radius: 16px;
    color: #fff;
    padding: 4px;
    box-shadow: 0 0 4px 2px #88b5b9;
}
.zfg-post__metacritic {
    background: orangered;
}
.zfg-post__preview {
    background: #0c6265;
}
.zfg-post__review {
    background: darkolivegreen;
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }
@media screen and (max-width: 1024px) {
    .zfg-post {
        position: relative;
        width: calc(100vw - 56px);
        display: flex;
        background: #fff;
        border-radius: 8px;
        padding: 12px;
        box-shadow: 0 0 12px 2px #88b5b9;
        transition: all 200ms ease-in-out;
        color: #012344;
    }
    .zfg-post__title {
         font-size: 0.7em;
         margin-top: 8px;
     }
    .zfg-post__synopsis {
        font-size: 0.5em;
        margin-top: 8px;
    }
    .zfg-post__author {
        font-size: 0.5em;
    }
}
</style>
