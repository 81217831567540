<template>
    <div class="zfg-filter">
        <div class="zfg-filter__search">
            <input type="text" placeholder="Search..." :value="state.searchString" @input="onSearchInput">
            <span class="material-symbols-rounded">search</span>
        </div>
        <div class="zfg-filter__categories">
            <span v-for="(cat, i) in categories"
                  :key="i"
                  @click="onCategorySelect(cat)"
                  :class="{ 'zfg-category__metacritic': state.categoryFilter.indexOf('Metacritic') > -1 && cat === 'Metacritic',
                            'zfg-category__preview': state.categoryFilter.indexOf('Preview') > -1 && cat === 'Preview',
                            'zfg-category__review': state.categoryFilter.indexOf('Review') > -1 && cat === 'Review'}">{{ cat }}</span>
        </div>
    </div>
</template>

<script>
import { reactive } from "vue";

export default {
    name: "FilterBar",
    props: {
        categories: {
            type: Array
        }
    },
    setup(props, { emit }){
        const state = reactive({
            searchString: "",
            categoryFilter: []
        })

        const onCategorySelect = (event) => {
            emit('categorySelect', event)

            if(state.categoryFilter.indexOf(event) < 0){
                state.categoryFilter.push(event)
            } else {
                state.categoryFilter.splice(state.categoryFilter.indexOf(event), 1)
            }
        }

        const onSearchInput = (event) => {
            emit('searchInput', event.target.value);
        }

        return {
            state,
            onCategorySelect,
            onSearchInput
        }
    }
}
</script>

<style scoped>
.zfg-filter {
    font-family: Poppins;
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    /*box-shadow: 0 0 12px 2px #88b5b9;*/
    transition: all 200ms ease-in-out;
    color: #012344;
    width: 100%;
    min-width: 1060px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #628285;
    border-right: 2px solid #628285;
}
.zfg-filter input {
    border-radius: 8px;
    border: 1px solid #012344;
    padding: 8px;
    width: 500px;
    background: aliceblue;
}
.zfg-filter__search {
    position: relative;
    margin-right: 16px;
}
.zfg-filter__search span {
    position: absolute;
    right: 0;
    padding: 4px 8px;
    background: #0c6265;
    border-radius: 0 8px 8px 0;
    color: #fff;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}
.zfg-filter__search span:hover {
    background: #012344;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: Poppins;
}
::-moz-placeholder { /* Firefox 19+ */
    font-family: Poppins;
}
.zfg-filter__categories {
    font-size: 12px;
}
.zfg-filter__categories span {
    padding: 4px 8px;
    border-radius: 8px;
    margin-left: 6px;
    color: #0c6265;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    outline: 1px solid #012344;
}
.zfg-category__metacritic {
    background: orangered;
    color: #fff !important;
    outline: none !important;
}
.zfg-category__preview {
    background: #0c6265;
    color: #fff !important;
    outline: none !important;
}
.zfg-category__review {
    background: darkolivegreen;
    color: #fff !important;
    outline: none !important;
}
@media screen and (max-width: 1024px) {
    .zfg-filter {
        font-family: Poppins;
        background: #fff;
        border-radius: 8px;
        padding: 12px;
        box-shadow: 0 0 12px 2px #88b5b9;
        transition: all 200ms ease-in-out;
        color: #012344;
        max-width: 100vw;
        width: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        min-width: calc(100% - 24px);
    }
    .zfg-filter__categories {
        margin-top: 12px;
        font-size: 12px;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(6, 1fr);
        grid-row-gap: 8px;
    }
    .zfg-filter__categories span {
        margin-left: 0;
        margin-right: 6px;
    }
    .zfg-filter input {
        border-radius: 8px;
        border: 1px solid #012344;
        padding: 8px;
        width: 100%;
    }
    .zfg-filter__search {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
}
</style>
